import React from "react"
import {Link, graphql} from "gatsby"
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import PopupModel from "../components/popup-model";
import {Helmet} from "react-helmet";
import images from "../components/images";
export default ({data}) => {

    return (
        <>

            <Helmet>
                <html lang="en" />
                <title>Tags List | Metrify India</title>
                <meta name="description" content="All tags of Legal Metrology"/>

                <meta name="robots"
                      content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Tags List | Metrify India"/>
                <meta property="og:description" content="All tags of Legal Metrology"/>
                <meta property="og:url" content="https://www.metrifyindia.com/tags/"/>
                <meta property="og:site_name" content="Metrify India"/>
                <meta property="og:image" content={data.site.siteMetadata.siteUrl + images.ogLogo} />
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:label1" content="Written by"/>
                <meta name="twitter:data1" content="admin"/>
                <meta name="twitter:label2" content="Est. reading time"/>
                <meta name="twitter:data2" content="14 minutes"/>
                <script type="application/ld+json"
                        className="yoast-schema-graph">{`{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://www.metrifyindia.com/#website","url":"https://www.metrifyindia.com/","name":"Metrify India","description":"All tags of Legal Metrology","potentialAction":[{"@type":"SearchAction","target":"https://www.metrifyindia.com/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://www.metrifyindia.com/tags/","url":"https://www.metrifyindia.com/tags/","name":"Tags List | Metrify India","isPartOf":{"@id":"https://www.metrifyindia.com/#website"},"datePublished":"2021-04-10T17:52:00+00:00","dateModified":"2021-04-15T07:33:19+00:00","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.metrifyindia.com/tags/"]}]}]}`}</script>
                <link rel="alternate" type="application/rss+xml" title="Metrify India &raquo; Feed"
                      href="https://www.metrifyindia.com/feed/"/>
                <link rel="alternate" type="application/rss+xml" title="Metrify India  &raquo; Comments Feed"
                      href="https://www.metrifyindia.com/comments/feed/"/>
            </Helmet>
            <Header />
            <Menu/>

            <div className="container pb-5 px-5 px-md-0">

                <div className="section-title pt-5">
                    <h1 className="section-title-heading fsm-theme-heading">Tags</h1>

                </div>
                <div className="row">
                    {data.allWordpressTag.edges.filter(e => e.node.count > 0).map(({node}) => (

                        <div className="col px-1">
                            <Link className="fsm-theme-small-text btn-pill text-decoration-none bg-color-dark-blue-hover hover-text-white  py-1   px-2 text-center bd-1 bdr-0-hover bd-normal my-1 d-block w-auto text-nowrap text-capitalize" to={new URL(node.link).pathname} dangerouslySetInnerHTML={{__html: node.name}} />
                        </div>
                    ))}
                </div>
            </div>


            <Footer />

            <PopupModel name={"formModal"} />
        </>
    )
}

export const pageQuery = graphql`
  query {
  site {
      siteMetadata {
        title
        siteUrl
      }
    }
  allWordpressTag {
    edges {
      node {
        link
        name
        wordpress_id
        count
      }
    }
  }
  }`
